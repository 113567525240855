<template>
  <base-material-card id="economyView">
    <template v-if="$vuetify.breakpoint.xsOnly">
      <v-row align="center">
        <v-col :cols="cashRegisters.length >= 3 ? 6 : 11" class="py-0">
          <v-select
            class="my-4"
            :items="tabs"
            item-text="text"
            item-value="href"
            v-model="tab"
            outlined
            dense
            hide-details
          ></v-select>
        </v-col>
        <v-col cols="5" class="py-0" v-if="cashRegisters.length >= 3">
          <v-select
            class="d-inline-block"
            outlined
            dense
            hide-details
            v-model="cash_register_id"
            :items="cashRegisters"
            item-text="name"
            item-value="id"
          ></v-select>
        </v-col>
        <CashRegisterList @update="fetchCashRegister" />
      </v-row>
    </template>
    <v-tabs
      v-if="!$vuetify.breakpoint.xsOnly"
      v-model="tab"
      right
      class="pl-0 pb-0"
      style="border-color: white; border-bottom: -2px; border-width: 1px"
    >
      <v-tab
        href="#operation"
        class="ml-0 px-0"
        active-class="color"
        style="font-size: 14px"
      >
        {{ $t("actions") }}
      </v-tab>
      <v-tab
        href="#general"
        class="pl-0 ml-0 pr-0"
        active-class="color"
        style="font-size: 14px"
        >{{ $t("general") }}</v-tab
      >
      <v-tab
        href="#cash"
        @click="refreshCashClose()"
        active-class="color"
        style="font-size: 14px"
        >{{ $t("expenses.cash_close") }}</v-tab
      >

      <v-tab
        href="#commissions"
        @click="refreshCommissions()"
        active-class="color"
        style="font-size: 14px"
        >{{ $t("expenses.pendings") }}</v-tab
      >

      <v-row
        justify="end"
        align="center"
        v-if="$store.getters['auth/canSeeCashRegisters']"
      >
        <v-col cols="3" v-if="cashRegisters.length >= 3">
          <v-select
            outlined
            dense
            hide-details
            v-model="cash_register_id"
            :items="cashRegisters"
            item-text="name"
            item-value="id"
          ></v-select>
        </v-col>
        <CashRegisterList
          @update="fetchCashRegister"
          v-if="$store.getters['auth/canEditCashRegisters']"
        />
      </v-row>
    </v-tabs>

    <v-tabs-items v-model="tab" style="border-color: red; border-width: medium">
      <v-tab-item value="operation">
        <SimpleOperations @to_debit="tab = 'commissions'" />
      </v-tab-item>
      <v-tab-item value="general">
        <v-row justify="center" class="pa-0 pa-md-4">
          <v-col class="py-0" id="time_select" cols="12" md="4">
            <v-btn-toggle v-model="btn" class="mt-1" mandatory>
              <v-btn
                value="all"
                active-class="selected"
                outlined
                height="30"
                @click="day = null"
              >
                {{ $t("all") }}
              </v-btn>
              <v-menu
                v-model="menu.day"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-on="on"
                    value="day"
                    active-class="selected"
                    outlined
                    height="30"
                    >{{ $t("day") }}</v-btn
                  >
                </template>
                <v-date-picker
                  first-day-of-week="1"
                  no-title
                  v-model="day"
                  @input="menu.day = false"
                  color="primary"
                ></v-date-picker>
              </v-menu>

              <v-menu
                v-model="menu.week"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-on="on"
                    value="week"
                    active-class="selected"
                    outlined
                    height="30"
                    >{{ $t("week") }}</v-btn
                  >
                </template>
                <v-date-picker
                  first-day-of-week="1"
                  no-title
                  v-model="week"
                  @input="menu.week = false"
                  color="primary"
                  range
                  class="weekSelector"
                ></v-date-picker>
              </v-menu>
              <v-menu
                v-model="menu.month"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-on="on"
                    value="month"
                    active-class="selected"
                    outlined
                    height="30"
                  >
                    {{ $t("month") }}
                  </v-btn>
                </template>
                <v-date-picker
                  first-day-of-week="1"
                  no-title
                  v-model="month"
                  @input="menu.month = false"
                  type="month"
                  color="primary"
                ></v-date-picker>
              </v-menu>
            </v-btn-toggle>
          </v-col>
          <v-col class="py-0" cols="12" sm="">
            <v-row
              :style="
                $vuetify.breakpoint.xsOnly
                  ? 'display:flex; justify-content: center; align-items: center'
                  : ''
              "
            >
              <v-col
                cols="4"
                class="py-0"
                style="min-width: 140px; max-width: 140px"
                :style="
                  $vuetify.breakpoint.xsOnly
                    ? 'padding-right: 8px; padding-left: 15px'
                    : ''
                "
              >
                <v-menu
                  v-model="menu.first_day"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      class="daymenu"
                      outlined
                      dense
                      hide-details
                      :value="$d(new Date(from), 'date2digits')"
                      readonly
                      v-bind="attrs"
                      v-bind:class="{ 'error--text': from > to }"
                      v-on="on"
                    >
                      <template v-slot:prepend-inner
                        ><v-icon class="ma-1" small
                          >$appointments</v-icon
                        ></template
                      ></v-text-field
                    >
                  </template>
                  <v-date-picker
                    first-day-of-week="1"
                    v-model="from"
                    color="primary"
                    no-title
                    @input="menu.first_day = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <span
                v-if="$vuetify.breakpoint.xsOnly"
                class="mt-md-n8"
                style="font-weight: bolder; font-size: 20px"
                >-
              </span>
              <span v-else class="mt-2">- </span>
              <v-col
                cols="4"
                class="py-0"
                style="min-width: 140px; max-width: 140px"
                :style="
                  $vuetify.breakpoint.xsOnly
                    ? 'padding-right: 15px; padding-left: 8px'
                    : ''
                "
              >
                <v-menu
                  v-model="menu.last_day"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      class="daymenu"
                      outlined
                      dense
                      hide-details
                      :value="$d(new Date(to), 'date2digits')"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      v-bind:class="{ 'error--text': from > to }"
                    >
                      <template v-slot:prepend-inner
                        ><v-icon class="ma-1" small>$appointments</v-icon>
                      </template>
                    </v-text-field>
                  </template>
                  <v-date-picker
                    first-day-of-week="1"
                    v-model="to"
                    color="primary"
                    :min="from"
                    no-title
                    @input="menu.last_day = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-col>
          <v-col
            v-if="$store.getters['auth/isStudio']"
            cols="6"
            sm="3"
            md="2"
            class="py-0"
          >
            <v-select
              clearable
              v-model="tattooer_id"
              outlined
              dense
              hide-details
              class="icon"
              single-line
              :items="tattooers"
              :label="$tc('artist')"
              item-value="id"
            >
              <template slot="selection" slot-scope="data">
                {{ data.item.user.first_name }}
                {{ data.item.user.last_name }}
              </template>
              <template slot="item" slot-scope="data">
                {{ data.item.user.first_name }}
                {{ data.item.user.last_name }}
              </template>
            </v-select>
          </v-col>

          <v-col cols="6" sm="3" md="2" class="py-0">
            <v-menu v-model="others">
              <template v-slot:activator="{ on }">
                <label
                  style="position: absolute; font-size: 10px !important"
                  v-bind:style="[
                    {
                      top: $vuetify.breakpoint.xsOnly ? '115px' : '70px',
                    },
                  ]"
                >
                  {{ $t(other_filters_applied ? "applyed" : "") }}
                </label>
                <v-btn
                  :outlined="!other_filters_applied"
                  block
                  elevation="0"
                  style="height: 30px; margin-top: 5px; width: 100%"
                  dense
                  v-on="on"
                >
                  {{ $t("other_filters") }}</v-btn
                >
              </template>
              <v-list
                style="max-width: 260px"
                id="others_expenses"
                class="pl-2"
              >
                <v-list-item class="mt-n2">
                  <v-select
                    clearable
                    v-model="filter_type"
                    @input="others = false"
                    dense
                    hide-details
                    class="icon filters no-line name mt-0"
                    single-line
                    @click.stop
                    :items="comes"
                    :label="$tc('economys.come')"
                  >
                  </v-select>
                </v-list-item>
                <v-list-item>
                  <v-select
                    clearable
                    v-model="pay_mode"
                    @input="others = false"
                    dense
                    style="min-width: 150px !important"
                    hide-details
                    class="icon filters no-line name"
                    single-line
                    @click.stop
                    :items="pay"
                    :label="$tc('expenses.pay_mode')"
                  >
                  </v-select>
                </v-list-item>
                <v-list-item>
                  <v-select
                    clearable
                    v-model="recurrent"
                    @input="others = false"
                    dense
                    style="min-width: 150px !important"
                    hide-details
                    class="icon filters no-line name"
                    single-line
                    @click.stop
                    :items="recurrent_list"
                    :label="$tc('expenses.recurrente_ask')"
                  >
                  </v-select>
                </v-list-item> </v-list
            ></v-menu>
          </v-col>
        </v-row>

        <v-row>
          <v-spacer></v-spacer>
          <v-col cols="12" sm="6" md="3">
            <expenses-table
              ref="incomeTable"
              type="confirmed_ingresos"
              :title="$t('expenses.incomeTable')"
              @update="updateTables"
              height="110px"
            ></expenses-table>
          </v-col>
          <v-col cols="12" sm="6" md="3">
            <expenses-table
              ref="expensesTable"
              type="confirmed_gastos"
              typeOfCard="outlined"
              :title="$t('expenses.expensesTable')"
              @update="updateTables"
              height="110px"
              @to_debit="tab = 'commissions'"
            ></expenses-table>
          </v-col>

          <v-col cols="12" sm="6" md="3">
            <expenses-table
              ref="total"
              type="total"
              :title="$t('expenses.total')"
              @update="updateTables"
              height="110px"
            ></expenses-table>
          </v-col>
          <v-col cols="12" sm="6" md="3">
            <expenses-table
              ref="forecastTable"
              type="forecast"
              :title="$t('expenses.forecastTable')"
              @update="updateTables"
              height="110px"
            ></expenses-table>
          </v-col>

          <v-col cols="12">
            <base-data-table-cards
              :headers="headers"
              :headers_small="headers_small"
              :title="$t('economy')"
              :data="data"
              id="economy-table"
              :card_title="$t('economy')"
            >
              <template v-slot:item_date="item">
                {{ $d(new Date(item.date), "dateShort") }}
              </template>
              <template v-slot:item_income="item">
                {{ $n(item.income, "currency") }}
              </template>
              <template v-slot:item_outcome="item">
                {{ $n(item.outcome, "currency") }}
              </template>
              <template v-slot:item_total="item">
                {{ $n(item.total, "currency") }}
              </template>
              <template v-slot:item_total_acum="item">
                {{ $n(item.total_acum, "currency") }}
              </template>
              <template v-slot:item_cash="item">
                {{ $n(item.cash, "currency") }}
              </template>
              <template v-slot:item_cash_acum="item">
                {{ $n(item.cash_acum, "currency") }}
              </template>
            </base-data-table-cards>
          </v-col>
        </v-row>
      </v-tab-item>
      <v-tab-item value="cash"> <CashClose ref="cash"></CashClose></v-tab-item>
      <v-tab-item value="commissions">
        <Commissions ref="commissions" />
      </v-tab-item>
    </v-tabs-items>
  </base-material-card>
</template>
<script>
import { mapActions, mapState } from "vuex";
import * as moment from "moment";
export default {
  name: "Economy",
  components: {
    ExpensesTable: () => import("@/components/expense/NewTable"),
    ExpensesStats: () => import("@/components/expense/ExpensesStats"),
    CashClose: () => import("@/components/expense/CashClose"),
    CashRegisterList: () => import("@/components/expense/CashRegisterList"),
    Commissions: () => import("@/components/expense/Commissions"),
    SimpleOperations: () => import("@/components/expense/SimpleOperations"),
  },

  data() {
    return {
      cashRegisters: [],
      tab: "general",
      menu: {
        month: false,
        day: false,
        week: false,
      },
      others: false,
      headers: [
        {
          text: this.$tc("expenses.data", 2),
          align: "center",
          sortable: false,
          value: "date",
        },
        {
          text: this.$tc("expenses.incomeTable", 2),
          align: "center",
          sortable: false,
          value: "income",
        },
        {
          text: this.$tc("expenses.expensesTable", 2),
          align: "center",
          sortable: false,
          value: "outcome",
        },

        {
          text: this.$t("expenses.total_saldo"),
          align: "center",
          sortable: false,
          value: "total",
        },
        {
          text: this.$tc("expenses.total_acum", 2),
          align: "center",
          sortable: false,
          value: "total_acum",
        },

        {
          text: this.$t("expenses.cash"),
          align: "center",
          sortable: false,
          value: "cash",
        },

        {
          text: this.$t("expenses.cash_acum"),
          align: "center",
          sortable: false,
          value: "cash_acum",
        },
      ],
      data: null,
      tattooers: [],
      headers_small: [
        { value: "date", class: "text-center" },
        { value: "income", class: "text-center" },
        { value: "outcome", class: "text-center" },
        { value: "total", class: "text-center" },
      ],

      comes: [
        { text: this.$t("economys.comes.all"), value: "all" },
        { text: this.$t("economys.comes.inventory"), value: "inventory" },
        { text: this.$t("economys.comes.appointment"), value: "appointment" },
        { text: this.$t("economys.comes.boxes"), value: "boxes" },
        // { text: this.$t("economys.comes.sell"), value: "sell" },
      ],
      recurrent_list: [
        {
          text: this.$t("yes"),
          value: true,
        },
        {
          text: this.$t("no"),
          value: false,
        },
      ],
    };
  },
  watch: {
    filtersState: {
      handler() {
        this.fetchTable();
      },
      deep: true,
    },
  },
  computed: {
    other_filters_applied() {
      let keys = Object.keys(this.filtersState);

      for (let i = 0; i < keys.length; i++) {
        let key = keys[i];
        let filter = this.filtersState[key];
        console.log(key, filter);

        if (
          filter !== "undefined" &&
          filter !== undefined &&
          filter !== "" &&
          filter !== null
        ) {
          switch (key) {
            case "type":
            case "recurrent":
            case "pay_mode":
              return true;
          }
        }
      }
      return false;
    },
    btn() {
      if (this.from && this.to) {
        var date1 = new Date(this.from);
        var date2 = new Date(this.to);

        // To calculate the time difference of two dates
        var Difference_In_Time = date2.getTime() - date1.getTime();

        // To calculate the no. of days between two dates
        var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);

        if (Difference_In_Days === 0) return "day";
        else if (Difference_In_Days === 6) return "week";
        else return "month";
      }
      return "all";
    },
    tabs() {
      if (this.$store.getters["auth/isStudio"])
        return [
          { href: "operation", text: this.$t("actions") },
          { href: "general", text: this.$t("general") },
          { href: "cash", text: this.$t("expenses.cash_close") },
          { href: "commissions", text: this.$t("expenses.pendings") },
        ];
      else
        return [
          { href: "general", text: this.$t("general") },
          { href: "cash", text: this.$t("expenses.cash_close") },
          { href: "commissions", text: this.$t("expenses.pendings") },
        ];
    },
    ...mapState("expenses", ["filtersState"]),
    from: {
      get() {
        return this.filtersState.from;
      },
      set(value) {
        this.$store.commit("expenses/SET_EXPENSES_FROM", value);
      },
    },
    to: {
      get() {
        return this.filtersState.to;
      },
      set(value) {
        this.$store.commit("expenses/SET_EXPENSES_TO", value);
      },
    },

    filter_type: {
      get() {
        return this.filtersState.type;
      },
      set(value) {
        this.$store.commit("expenses/SET_EXPENSES_TYPE", value);
      },
    },
    pay_mode: {
      get() {
        return this.filtersState.pay_mode;
      },
      set(value) {
        this.$store.commit("expenses/SET_EXPENSES_PAY_MODE", value);
      },
    },
    recurrent: {
      get() {
        return this.filtersState.recurrent;
      },
      set(value) {
        this.$store.commit("expenses/SET_EXPENSES_RECURRENT", value);
      },
    },

    tattooer_id: {
      get() {
        return this.filtersState.tattooer_id;
      },
      set(value) {
        this.$store.commit("expenses/SET_EXPENSES_TATTOOER_ID", value);
      },
    },

    pay() {
      let p = [
        {
          text: this.$t("expenses.cash"),
          value: "cash",
        },
        {
          text: this.$t("expenses.card"),
          value: "card",
        },
      ];
      if (
        this.$store.getters["auth/getSetting"]("pm_stripe_id") !== 0 &&
        this.type !== "confirmed_gastos"
      ) {
        p.push({
          text: this.$t("expenses.online"),
          value: "online",
        });
      }
      return p;
    },
    month: {
      get() {
        if (this.from) {
          let split = this.from.split("-");
          return `${split[0]}-${split[1]}`;
        }
        return null;
      },
      set(value) {
        //console.log("%c%s", "color: #00e600", value);
        value = value.split("-");
        let from = new Date(value[0], value[1] - 1, 1);
        this.from = `${from.getFullYear()}-${
          from.getMonth() + 1 >= 10
            ? +from.getMonth() + 1
            : "0" + (+from.getMonth() + 1)
        }-${from.getDate() >= 10 ? from.getDate() : "0" + from.getDate()}`;

        let to = new Date(value[0], value[1], 0);
        //console.log(from);
        //console.log(to);
        this.to = `${to.getFullYear()}-${
          to.getMonth() + 1 >= 10
            ? +to.getMonth() + 1
            : "0" + (+to.getMonth() + 1)
        }-${to.getDate() >= 10 ? to.getDate() : "0" + to.getDate()}`;
      },
    },
    week: {
      get() {
        //return [];
        if (this.from) return [this.from, this.to];
        return null;
      },
      set(value) {
        value = value[0];
        value = new Date(value);
        let d = value.getDate() + 1;
        let di = value.getDay() === 0 ? 7 : value.getDay();
        value.setDate(d - di);
        this.from = `${value.getFullYear()}-${
          value.getMonth() + 1 >= 10
            ? value.getMonth() + 1
            : "0" + (value.getMonth() + 1)
        }-${value.getDate() >= 10 ? value.getDate() : "0" + value.getDate()}`;

        value.setDate(value.getDate() + 6);

        this.to = `${value.getFullYear()}-${
          value.getMonth() + 1 >= 10
            ? value.getMonth() + 1
            : "0" + (value.getMonth() + 1)
        }-${value.getDate() >= 10 ? value.getDate() : "0" + value.getDate()}`;
      },
    },
    day: {
      get() {
        return this.from;
      },
      set(value) {
        console.log("day", value);
        this.from = value;
        this.to = value;
      },
    },
    cash_register_id: {
      get() {
        return this.filtersState.cash_register_id;
      },
      set(value) {
        this.$store.commit("expenses/SET_EXPENSES_CASH_REGISTER_ID", value);
      },
    },
  },
  mounted() {
    this.tab = this.$route.params.type ? this.$route.params.type : "operation";
    this.day = moment().format("YYYY-MM-DD");
    this.fetchTable();
    this.fetchActiveTattooer();
    this.fetchCashRegister();
  },
  methods: {
    ...mapActions("cash_register", [
      "getCashRegisters",
      "getDefaultCashRegister",
    ]),
    fetchCashRegister() {
      this.getCashRegisters({ paginator: { itemPerPage: -1 } }).then(
        (response) => {
          console.log("cash registers", response);
          this.cashRegisters = [
            { name: "TODAS LAS CAJAS", id: null },
            ...this.$store.getters["auth/filterCashRegisters"](response.data),
          ];
        }
      );
      this.getDefaultCashRegister().then((response) => {
        console.log("default cash register", response);
        this.$store.commit(
          "expenses/SET_EXPENSES_CASH_REGISTER_ID",
          response.id
        );
      });
    },
    ...mapActions("studios", ["getActiveTattooer"]),
    fetchActiveTattooer() {
      this.getActiveTattooer().then(
        (data) => (this.tattooers = data.map((x) => x.tattooer))
      );
    },
    refreshCashClose() {
      this.$refs.cash.fetchExpenses();
      this.$refs.cash.reload();
    },
    refreshCommissions() {
      this.$refs.commissions.fetchExpense();
    },
    updateTables() {
      Object.keys(this.$refs).forEach((el) => {
        if (el !== "cash" && el !== "commissions") {
          this.$refs[el].reload();
        }
      });
    },
    ...mapActions("expenses", ["getExpensesDayByDay"]),
    fetchTable() {
      let to = moment().format("YYYY-MM-DD");
      let from = moment()
        .subtract(4, "d")
        .format("YYYY-MM-DD");
      this.getExpensesDayByDay({
        filters: {
          from,
          to,
          cash_register_id: this.cash_register_id,
        },
      }).then((response) => {
        console.log(response);
        this.data = response;
      });
    },
  },
};
</script>
>

<style lang="sass" scoped>
#time_select
  @media (max-width: 960px)
    text-align: center
    margin-bottom: 8px
  .v-btn-toggle
    .v-btn
      min-width: unset
      padding-right: 25px !important
      padding-left: 25px !important

#economyView
  .v-card .v-card__title
    background-color: #1e1e1e !important
</style>
<style lang="sass">
#economy-table
  tbody
    tr:nth-child(1) td:nth-child(5),
    tr:nth-child(1) td:nth-child(7),
    td:nth-child(4)
      color: var(--v-primary-base)
      font-weight: 700
#economyView
  .v-tab-slider
    position: absolute

  @media (max-width:960px)
    #economy-table
      .row
        .col
          .row
            .col
              &:nth-child(2)
                color: #59B075 !important
              &:nth-child(3)
                color: red !important
              &:nth-child(4)
                color: var(--v-primary-base) !important
                font-size: 18px
</style>
